import React, { useEffect, useState } from "react";
import { handleCopyClick } from "../utills/functions";


const ADDRESS = "서울 서초구 서초대로74길 11";
const About = () => {

  const handleNaverMapOpen = () => {
    const address = "서울 서초구 서초대로74길 11";
    const url = "https://map.naver.com/v5/search/" + encodeURIComponent(address);
    window.open(url, '_blank');
  };

  const [isOpen, setIsOpen] = useState(false);


  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="woongshmello_section" id="about">
        <div className="woongshmello_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img src="/img/placeholders/1-1.jpg" alt="placeholder" />

                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(img/photos/studio/99.jpg)",
                      backgroundPosition: "center 20%"
                    }}
                  ></div>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div
                  className="woongshmello_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>Invitation</span>
                  <h3>당신이 없으면 시작을 못해요</h3>
                </div>

                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  style={{ padding: "10px", marginBottom: "20px" }}
                >
                  <p style={{ fontSize: "16px" }}>웅제와 은경이가 결혼합니다!</p>
                  <p>늘 곁에서 아껴주셨던 고마운 분들을 모십니다.</p>
                  <p>시간 되신다면, 꼭 와서 함께 해주세요!😍</p>
                </div>


                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  style={{ padding: "10px" }}
                >
                  {/* <hr style={{ width: '20%', margin: '10px 5px' }} /> */}
                  <p><strong>한준식 ⦁ 김태경</strong> 의 아들 <strong>한웅제</strong></p>
                  <p><strong>권경택 ⦁ 김은화</strong> 의 &nbsp;&nbsp;&nbsp;딸 <strong>권은경</strong></p>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  style={{ padding: "10px", marginBottom: "20px" }}
                >
                  <p><strong>2024년 6월 30일 일요일 오전 11시</strong></p>
                  <p onClick={() => handleCopyClick(ADDRESS)} style={{ cursor: 'pointer' }}>삼성전자 서초사옥 5층 다목적홀</p>
                  <p onClick={() => handleCopyClick(ADDRESS)} style={{ cursor: 'pointer' }}>서울 서초구 서초대로74길 11
                    <img
                      src="/img/copy.svg"
                      alt="Copy icon"
                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                    />
                  </p>
                  <p>(강남역 8번 출구 앞)</p>
                </div>
                <div
                  className="shane_tm_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a href="#location">
                    오시는길
                  </a>
                </div>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
