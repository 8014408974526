import React from "react";
import Slider from "../components/Slider";
import About from "../components/About";
import MyPhotos from "../components/MyPhotos";
import LocationInfo from "../components/LocationInfo";
import Footer from "../components/Footer";
import Gratitude from "../components/Gratitude";

const Home = () => {
    return (
        <div className="home">
            <Slider></Slider>
            <About></About>
            <LocationInfo></LocationInfo>
            <MyPhotos></MyPhotos>
            <Gratitude></Gratitude>
            <Footer></Footer>
        </div>
    );
};

export default Home;
