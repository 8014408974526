import React from "react";
import Carroussel from "./Carousel";
import Card from "./Card";
const snapPhotos = [];

for (let i = 1; i <= 32; i++) {
  snapPhotos.push({
    key: i,
    content: (
      <Card imagen={`/img/photos/snap/${i}.jpg`} />
    )
  });
}

const studioPhotos = [];
for (let i = 1; i <= 45; i++) {
  studioPhotos.push({
    key: i,
    content: (
      <Card imagen={`/img/photos/studio/${i}.jpg`} />
    )
  });
}

const MyPhotos = () => {


  return (
    <div className="woongshmello_section" id="photos" >
      <div className="woongshmello_photos">
        <div className="container">
          <div className="positon-relative">
            <div className="woongshmello_title">
              <div className="title_flex">
                <div className="left">
                  <span>Photos</span>
                  <h3>찬란한 순간들</h3>
                </div>
              </div>
            </div>
            {/* End title */}

            <div className="photo_filter" >
              {/* End tablist */}
              <div className="photo_list has-effect">
                <p className="section">Snap</p>
                <Carroussel
                  cards={snapPhotos}
                  height="500px"
                  width="100%"
                  margin="0 auto"
                  offset={200}
                  showArrows={false}
                />

                <p className="section">Studio</p>
                <Carroussel
                  cards={studioPhotos}
                  height="500px"
                  width="100%"
                  margin="0 auto"
                  offset={200}
                  showArrows={false}
                />




              </div>
              {/* End list wrapper */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default MyPhotos;
