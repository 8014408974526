import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// export const handleCopyClick = (info) => {
//     navigator.clipboard.writeText(info)
//         .then(() => alert(`클립보드에 복사됐어요!\n${info}`))
//         .catch(err => console.error('복사에 실패했습니다: ', err));
// };

export const handleNaverMapOpen = (address) => {
    const url = "https://map.naver.com/v5/search/" + encodeURIComponent(address);
    window.open(url, '_blank');
};



export const handleCopyClick = (info) => {

    navigator.clipboard.writeText(info)
        .then(() => {
            toast.success(`클립보드에 복사됐어요: \n${info}`, {
                position: "top-center",
                autoClose: 1.5 * 1000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                style: {
                    whiteSpace: 'pre-line',
                }
            });
        })
        .catch(err => {
            toast.error('복사에 실패했습니다: ' + err.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
            });
        });
};