import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function Card({ imagen }) {
    const [show, setShown] = useState(false);

    const props3 = useSpring({
        opacity: 1,
        transform: show ? "scale(1.03)" : "scale(1)",
        boxShadow: show
            ? "0 20px 25px rgb(0 0 0 / 25%)"
            : "0 2px 10px rgb(0 0 0 / 8%)"
    });

    // props3 객체의 유효성 검사
    if (!props3) {
        console.error('props3 is not defined');
        return null; // 또는 로딩 컴포넌트 등을 렌더링
    }

    return (
        <animated.div
            className={Styles.card}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
        >
            <img src={imagen} alt="" />

        </animated.div>
    );
}

export default Card;
