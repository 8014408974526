import React, { useState } from "react";
import Modal from "react-modal";
import ModalAccount from "./ModalAccount";
Modal.setAppElement("#root");

const Gratitude = () => {
  const [isModalAcAccountOpen, setIsModalAccountOpen] = useState(false);

  function toggleModalAccountOpen() {
    setIsModalAccountOpen(!isModalAcAccountOpen);
  }

  return (
    <div className="shane_tm_section" id="contact">
      <div
        className="shane_tm_talk bg_image_props"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/photos/studio/7.jpg"
          })`,
          backgroundPosition: 'center 10%'
        }}
      >
        <div className="shape">
          <img className="svg" src="/img/paper.svg" alt="partners brand" />
        </div>
        {/* End shape */}

        <div className="background" id="talk">
          <a className="player"></a>
          <div className="overlay"></div>
        </div>
        {/* End background */}

        <div className="talk_inner">
          <div className="text">
            <h4>Gratitude</h4>
            <p>멀리서도 저희의 행복을 함께 나누어 주셔서</p>
            <p>진심으로 감사합니다</p>
          </div>
          <div className="button">
            <button className="white-fill-bg" onClick={toggleModalAccountOpen}>
              마음 전하실 곳
            </button>
          </div>
        </div>
        {/* End talk_inner */}
      </div>
      {/* Start Modal */}
      <Modal
        isOpen={isModalAcAccountOpen}
        onRequestClose={toggleModalAccountOpen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
      <ModalAccount isOpen={isModalAcAccountOpen} setIsOpen={setIsModalAccountOpen}>
      </ModalAccount>
        {/* End modalbox news */}
      </Modal>
      {/* End modal */}
    </div>
  );
};

export default Gratitude;
