import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { handleCopyClick, handleNaverMapOpen } from "../utills/functions";

Modal.setAppElement("#root");

const ModalBusInfo = ({ isOpen, setIsOpen }) => {

    const closeModal = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        // 페이지에 진입할 때 현재 상태를 히스토리에 추가
        if (isOpen) {
            window.history.pushState(null, null, window.location.href);
        }

        const handlePopState = (event) => {
            // 모달이 열려있으면 닫고, 히스토리 스택에서 마지막 항목 제거
            if (isOpen) {
                closeModal();
                window.history.pushState(null, null, window.location.href); // 이전 상태로 복귀하지 않도록 현재 위치를 다시 푸시
            }
        };

        // 이벤트 리스너 등록
        window.addEventListener('popstate', handlePopState);

        // 컴포넌트가 언마운트되거나 업데이트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isOpen, closeModal]); // 의존성 배열에 isOpen과 closeModal을 추가하여, 이 값들이 변경될 때마다 useEffect를 다시 실행


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="My dialog"
            className="custom-modal"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
        >
            <div className="shane_tm_mobalbox_contact">
                <button className="close-modal" onClick={closeModal}>
                    <img src="/img/cancel.svg" alt="close icon" />
                </button>
                {/* End close icon */}
                <div className="box_inner">
                    <div className="description_wrap scrollable">
                        {/* Start modal content */}
                        <div className="title">
                            <h3>대절버스 안내</h3>
                            <p>장소를 클릭하면 지도로 연결됩니다.</p>
                            <p style={{color:"red"}}>※탑승하실 분들은 사전에 꼭 연락부탁드립니다.</p>
                        </div>
                        {/* End title */}

                        <div className="wrapper">
                            <div className="left">
                                <div className="fields">
                                    <h4>대구 출발</h4>
                                    <div className="host-account-info" onClick={() => handleNaverMapOpen("대구광역시 동구 신서동 497-2")}>
                                        <div className="info">
                                            <div>오전 <span style={{ fontWeight: "bold" }}>06:00</span></div>
                                            <div>대구 동구 칼라디움웨딩홀 앞 🔗</div>
                                        </div>
                                    </div>
                                    <div className="host-account-info" onClick={() => handleNaverMapOpen("대구광역시 동구 지저동 406-6")}>
                                        <div className="info">
                                            <div>오전 <span style={{ fontWeight: "bold" }}>06:30</span></div>
                                            <div>대구국제공항앞 버스정류장 🔗</div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                            {/* End left */}
                            <div className="right">
                                <div className="map_wrap" style={{ marginBottom: "120px" }}>
                                    <h4>울산 출발(경주 경유)</h4>
                                    <div className="host-account-info" onClick={() => handleNaverMapOpen("울산광역시 남구 달동 970-1")}>
                                        <div className="info">
                                            <div>오전 <span style={{ fontWeight: "bold" }}>04:30</span></div>
                                            <div>울산 남구 달동 굿모닝병원 앞 🔗</div>
                                            <div>(강남초등학교 버스정류장)</div>
                                        </div>
                                        {/* <a className="copy-button">복사</a> */}
                                    </div>
                                    <div className="host-account-info" onClick={() => handleNaverMapOpen("울산광역시 남구 문수로 291-1")}>
                                        <div className="info">
                                            <div>오전 <span style={{ fontWeight: "bold" }}>04:40</span></div>
                                            <div>법조타운 삼거리 인근 🔗</div>
                                            <div>(옥동중학교앞 버스정류장)</div>
                                        </div>
                                    </div>
                                    <div className="host-account-info" onClick={() => handleNaverMapOpen("경상북도 경주시 서라벌대로 420")}>
                                        <div className="info">
                                            <div>오전 <span style={{ fontWeight: "bold" }}>05:15</span></div>
                                            <div>경주톨게이트 경주IC휴게소 🔗</div>
                                            <div>주차장</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End right */}
                        </div>
                        {/* End wrapper */}

                        {/* End modal conetent */}
                    </div>
                </div>
                {/* End box inner */}
            </div>
            {/* End modalbox news */}
        </Modal>
    )
};

export default ModalBusInfo;
