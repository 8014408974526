import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { handleCopyClick } from "../utills/functions";

Modal.setAppElement("#root");

const ModalAccount = ({ isOpen, setIsOpen }) => {

    const closeModal = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        // 페이지에 진입할 때 현재 상태를 히스토리에 추가
        if (isOpen) {
            window.history.pushState(null, null, window.location.href);
        }

        const handlePopState = (event) => {
            // 모달이 열려있으면 닫고, 히스토리 스택에서 마지막 항목 제거
            if (isOpen) {
                closeModal();
                window.history.pushState(null, null, window.location.href); // 이전 상태로 복귀하지 않도록 현재 위치를 다시 푸시
            }
        };

        // 이벤트 리스너 등록
        window.addEventListener('popstate', handlePopState);

        // 컴포넌트가 언마운트되거나 업데이트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isOpen, closeModal]); // 의존성 배열에 isOpen과 closeModal을 추가하여, 이 값들이 변경될 때마다 useEffect를 다시 실행


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="My dialog"
            className="custom-modal"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
        >
            <div className="shane_tm_mobalbox_contact">
                <button className="close-modal" onClick={closeModal}>
                    <img src="/img/cancel.svg" alt="close icon" />
                </button>
                {/* End close icon */}
                <div className="box_inner">
                    <div className="description_wrap scrollable">
                        {/* Start modal content */}
                        <div className="title">
                            <h3>혼주 계좌번호 안내</h3>
                        </div>
                        {/* End title */}

                        <div className="wrapper">
                            <div className="left">
                                <div className="fields">
                                    <h4>🤵신랑측 계좌번호</h4>
                                    <div className="host-account-info" onClick={() => handleCopyClick("카카오뱅크 79798984300")}>
                                        <div className="info">
                                            <div>신랑 <span style={{ fontWeight: "bold" }}>한웅제</span></div>
                                            <div>카카오 79798984300</div>
                                        </div>
                                        <a className="copy-button">복사</a>
                                    </div>
                                    <div className="host-account-info" onClick={() => handleCopyClick("대구은행 17913072373")}>
                                        <div className="info">
                                            <div>아버지 <span style={{ fontWeight: "bold" }}>한준식</span></div>
                                            <div>대구 179 13 072373</div>
                                        </div>
                                        <a className="copy-button">복사</a>
                                    </div>
                                    <div className="host-account-info" onClick={() => handleCopyClick("기업은행 01064904752")}>
                                        <div className="info">
                                            <div>어머니 <span style={{ fontWeight: "bold" }}>김태경</span></div>
                                            <div>기업 010 6490 4752</div>
                                        </div>
                                        <a className="copy-button">복사</a>
                                    </div>



                                </div>
                            </div>
                            {/* End left */}
                            <div className="right">
                                <div className="map_wrap"  style={{ marginBottom: "120px" }}>
                                    <h4>👰신부측 계좌번호</h4>
                                    <div className="host-account-info" onClick={() => handleCopyClick("우리은행 1002180812581")}>
                                        <div className="info">
                                            <div>신부 <span style={{ fontWeight: "bold" }}>권은경</span></div>
                                            <div>우리 1002 180 812581</div>
                                        </div>
                                        <a className="copy-button">복사</a>
                                    </div>
                                    <div className="host-account-info"  onClick={() => handleCopyClick("국민은행 86440101242518")}>
                                        <div className="info">
                                            <div>아버지 <span style={{ fontWeight: "bold" }}>권경택</span></div>
                                            <div>국민 864401 01 242518</div>
                                        </div>
                                        <a className="copy-button">복사</a>
                                    </div>
                                    <div className="host-account-info" onClick={() => handleCopyClick("농협 81507556056554")}>
                                        <div className="info">
                                            <div>어머니 <span style={{ fontWeight: "bold" }}>김은화</span></div>
                                            <div>농협 815075 56 056554</div>
                                        </div>
                                        <a className="copy-button">복사</a>
                                    </div>

                                </div>
                            </div>
                            {/* End right */}
                        </div>
                        {/* End wrapper */}

                        {/* End modal conetent */}
                    </div>
                </div>
                {/* End box inner */}
            </div>
            {/* End modalbox news */}
        </Modal>
    )
};

export default ModalAccount;
