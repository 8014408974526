import React, { useEffect, useState } from "react";
import { handleCopyClick, handleNaverMapOpen } from "../utills/functions";
import ModalBusInfo from "./ModalBusInfo";
const NAVER_MAP_CLIENT_ID = "ur2znqtz9c"
const ADDRESS = "서울 서초구 서초대로74길 11";

const LocationInfo = () => {

  const [isModalBusInfoOpen, setIsModalBusInfoOpen] = useState(false);

  function toggleModalBusInfoOpen() {
    setIsModalBusInfoOpen(!isModalBusInfoOpen);
  }

  const setupMap = () => {
    if (window.naver && window.naver.maps) {
      const map = new window.naver.maps.Map('mapz', {
        center: new window.naver.maps.LatLng(37.4966083, 127.0269028),//37.4966083° 동경 127.0269028°
        zoom: 16,
        scrollWheel: false,  // 마우스 스크롤로 확대/축소 방지
        disableKineticPan: true,  // 마우스 드래그 슬라이드 방지
        disableDoubleTapZoom: true,  // 더블탭으로 확대 방지
        draggable: false,
        pinchZoom: false,
        keyboardShortcuts: false,
        disableDoubleClickZoom: true,
        disableTwoFingerTapZoom: true
      });

      // 이미지 
      // 마커에 동그랗게 이미지를 넣음
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(37.4966083, 127.0269028),
        map: map,
        icon: {
          content: '<div style="width: 50px; height: 50px; border-radius: 50%; overflow: hidden; border: 5px solid #F5A9BC;"><img src="/img/photos/snap/12.jpg" style="width: 100%; height: 100%; object-fit: cover;"></div>',
          size: new window.naver.maps.Size(50, 50),
          anchor: new window.naver.maps.Point(25, 25)
        }
      });

      window.handleInfoWindowClick = () => {
        handleNaverMapOpen(ADDRESS);
      };

      const infowindow = new window.naver.maps.InfoWindow({
        content: '<a onclick="handleInfoWindowClick()" style="padding: 5px; text-decoration: none;">여기로 오세요👇(지도 열기)</a>',
        borderColor: '#FFC0CB',
        borderWidth: 2,
        backgroundColor: 'white'
      });

      // 지도 클릭 이벤트 리스너를 추가하여 인포 윈도우 토글
      window.naver.maps.Event.addListener(map, 'click', () => {
        if (infowindow.getMap()) {
          infowindow.close();
        } else {
          infowindow.open(map, marker);
        }
      });

      window.naver.maps.Event.addListener(marker, 'click', () => {
        handleNaverMapOpen(ADDRESS);
      });

      infowindow.open(map, marker);

    }
  };

  const loadNaverMapsScript = () => {
    const script = document.createElement('script');
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${NAVER_MAP_CLIENT_ID}`;
    script.async = true;
    script.onload = () => setupMap();  // 스크립트 로딩이 완료되면 지도 설정
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadNaverMapsScript();
    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.querySelectorAll('script[src*="naver"]').forEach(el => el.remove());
    };
  }, []);

  return (
    <>
      <div className="woongshmello_section" id="location">
        <div className="woongshmello_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <div id="mapz" style={{ width: '100%', height: '350px' }} />

                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div
                  className="woongshmello_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>Location</span>
                  <h3>어디서나 다가갈 수 있는 장소</h3>
                </div>

                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  style={{ padding: "10px", }}
                >
                  <p><strong>주소 안내</strong></p>
                  <p onClick={() => handleCopyClick(ADDRESS)} style={{ cursor: 'pointer' }}>서울 서초구 서초대로74길 11
                    <img
                      src="/img/copy.svg"
                      alt="Copy icon"
                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                    />
                  </p>
                  <p>삼성전자빌딩(삼성전자 서초사옥)</p>
                  <p>5층 다목적홀</p>
                </div>

                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  style={{ padding: "10px", }}
                >
                  <p><strong>주차 안내</strong></p>
                  <p>지하주차장 이용 가능(400대 수용)</p>
                  <p>이용시간: 3시간 무료 (주차권 도장 필요)</p>
                </div>

                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  style={{ padding: "10px", }}
                >
                  <p><strong>대절버스 안내 (대구, 울산) 🚌</strong></p>
                  <p>귀한 발걸음을 해주신 하객분들의 편의를 위해 전세버스를 준비하였습니다.</p>
                  <p>※아래 버튼을 눌러 탑승장소를 확인해주세요.</p>
                </div>

                <div
                  className="shane_tm_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a onClick={toggleModalBusInfoOpen}>
                    대절버스 확인 
                  </a>
                </div>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
      <ModalBusInfo isOpen={isModalBusInfoOpen} setIsOpen={setIsModalBusInfoOpen}>
      </ModalBusInfo>
    </>
  );
};

export default LocationInfo;
